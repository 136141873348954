import ModalComponents from "../../components/Modal";
import { Button } from "@mui/material";
import * as React from "react";
import { Screener } from "../../types/search";
import { useImmer } from "use-immer";
import { createContext, ReactNode, useContext, useEffect } from "react";
import axios, { AxiosError } from "axios";
import { ToastContext } from "../../providers/ToastProvider";

type DeleteScreenerModalContextValue = {
  openDeleteScreener: (screener: Screener, callbackFn?: () => void) => void;
};

export const DeleteScreenerModalContext =
  createContext<DeleteScreenerModalContextValue>({
    openDeleteScreener: (screener: Screener, callbackFn?: () => void) => {},
  });

const DeleteScreenerModal = ({
  open,
  onClose,
  screener,
  callbackFn,
}: {
  open: boolean;
  onClose: () => void;
  screener?: Screener;
  callbackFn: () => void;
}) => {
  const { setSuccess, setError } = useContext(ToastContext);

  useEffect(() => {
    if (screener) {
      setState((state) => {
        state.screener = screener;
      });
    }
  }, [screener]);

  const defaultState = {
    screener: undefined,
    requestPending: false,
  };

  const [state, setState] = useImmer<{
    screener?: Screener;
    requestPending: boolean;
  }>(defaultState);

  const onDelete = (callbackFn?: () => void) => {
    setState((state) => {
      state.requestPending = true;
    });
    axios({
      method: "DELETE",
      url: `/api/v1/screeners/${screener?.id}`,
      withCredentials: true,
    })
      .then((response) => {
        if (callbackFn) {
          callbackFn();
        }
        setSuccess("Screener deleted successfully");
      })
      .catch((reason: AxiosError) => {
        if (reason.response!.status === 403) {
          setError("Reached max number of screener deleted");
        } else {
          setError("Unable to delete screener, please try again");
        }
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <ModalComponents.ModalContainer
      open={open}
      onClose={() => {
        onClose();
        setState(defaultState);
      }}
    >
      <ModalComponents.ModalPage
        title={"Are you sure?"}
        body={
          <ModalComponents.ModalBodyText
            content={`This action cannot be undone. Are you sure you want to delete ${state.screener?.name}?`}
          />
        }
        buttons={
          <>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="outlined"
              color="error"
              onClick={() => {
                onDelete(callbackFn);
                onClose();
              }}
            >
              Delete
            </Button>
          </>
        }
      />
    </ModalComponents.ModalContainer>
  );
};

const DeleteScreenerModalProvider = ({ children }: { children: ReactNode }) => {
  const defaultState = {
    open: false,
    callbackFn: () => {},
    requestPending: false,
    screener: undefined,
  };

  const [state, setState] = useImmer<{
    open: boolean;
    callbackFn: () => void;
    requestPending: boolean;
    screener?: Screener;
  }>(defaultState);

  const onClose = () => {
    setState(defaultState);
  };

  const noneCallbackFn = () => {};

  const context = {
    openDeleteScreener: (screener: Screener, callbackFn?: () => void) => {
      setState((state) => {
        state.screener = screener;
        state.open = true;
        state.callbackFn = callbackFn || noneCallbackFn;
      });
    },
  };

  return (
    <DeleteScreenerModalContext.Provider value={context}>
      {children}
      <DeleteScreenerModal
        open={state.open}
        screener={state.screener}
        onClose={onClose}
        callbackFn={state.callbackFn}
      />
    </DeleteScreenerModalContext.Provider>
  );
};

export default DeleteScreenerModalProvider;
