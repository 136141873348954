import { Button, CircularProgress } from "@mui/material";
import * as React from "react";
import TruncatedList from "../../pages/watchlists/components/TruncatedList";
import { useImmer } from "use-immer";
import axios from "axios";
import { cleanDomains } from "../../pages/watchlists/components/utils";
import { createContext, useContext } from "react";
import { RemoveCircle } from "@mui/icons-material";

import ModalComponents from "../Modal";
import { DefaultOrganization, Organization } from "../../types/search";
import { ToastContext } from "../../providers/ToastProvider";
import { AlertColor } from "@mui/material/Alert";
import { SessionContext } from "../../providers/SessionProvider";

const RemoveIntentModal = ({
  organization,
  domainNames,
  open,
  onClose,
  refreshData,
}: {
  organization: Organization;
  domainNames: string[];
  open: boolean;
  onClose: () => void;
  refreshData: () => void;
}) => {
  const { setMessage, setError } = useContext(ToastContext);

  const [state, setState] = useImmer<{
    locked: boolean;
  }>({
    locked: false,
  });

  const onSubmit = async (domainNames: string[], onClose: () => void) => {
    setState((state) => {
      state.locked = true;
    });

    axios({
      method: "DELETE",
      url: `/api/v1/intent/batch`,
      data: {
        domain_names: cleanDomains(domainNames, false),
      },
      withCredentials: true,
    })
      .then((response) => {
        setMessage(
          response.data["toast"]["message"],
          response.data["toast"]["severity"] as AlertColor
        );
        refreshData();
        onClose();
      })
      .catch((error) => {
        setError(error.response.data["toast"]);
      })
      .finally(() => {
        setState((state) => {
          state.locked = false;
        });
      });
  };

  return (
    <ModalComponents.ModalContainer open={open} onClose={onClose}>
      <ModalComponents.ModalPage
        title={"Are you sure?"}
        body={
          <>
            <ModalComponents.ModalBodyText
              content={
                <>
                  <p>
                    Are you sure you no longer want to track these companies?
                  </p>
                  <p>
                    {organization.credits.monthlyRemoves} /{" "}
                    {organization.credits.granted} monthly removals remaining
                    (Resets beginning of each month).
                  </p>
                </>
              }
            />
            <TruncatedList
              domains={domainNames}
              maxRows={8}
              icon={<RemoveCircle color="error" />}
            />
          </>
        }
        buttons={
          <>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="error"
              variant="outlined"
              disabled={state.locked}
              onClick={() => {
                onSubmit(domainNames, onClose);
              }}
            >
              {state.locked ? (
                <>
                  Confirm <CircularProgress color="inherit" size={16} />
                </>
              ) : (
                "Confirm"
              )}
            </Button>
          </>
        }
      />
    </ModalComponents.ModalContainer>
  );
};

export type RemoveIntentModalContextValue = {
  show: (
    domains: string[],
    refreshData?: () => void,
    redirect?: boolean
  ) => void;
};

export const RemoveIntentModalContext =
  createContext<RemoveIntentModalContextValue>({
    show: (
      domains: string[],
      refreshData?: () => void,
      redirect?: boolean
    ) => {},
  });

const RemoveIntentModalProvider = ({ children }: { children: any }) => {
  const session = useContext(SessionContext);

  const [state, setState] = useImmer<{
    open: boolean;
    onClose: () => void;
    domains: string[];
    refreshData: () => void;
    redirect: boolean;
  }>({
    open: false,
    onClose: () => {},
    domains: [],
    refreshData: () => {},
    redirect: false,
  });

  const context = {
    show: (domains, refreshData?: () => void, redirect?: boolean) => {
      setState((state) => {
        state.domains = domains;
        state.refreshData = refreshData || (() => {});
        state.redirect = redirect || false;
        state.open = true;
      });
    },
  } as RemoveIntentModalContextValue;

  return (
    <RemoveIntentModalContext.Provider value={context}>
      {children}
      <RemoveIntentModal
        organization={session.user?.organization || DefaultOrganization}
        open={state.open}
        onClose={() => {
          setState((state) => {
            state.open = false;
            state.domains = [];
          });
        }}
        refreshData={state.refreshData}
        domainNames={state.domains}
      />
    </RemoveIntentModalContext.Provider>
  );
};

export default RemoveIntentModalProvider;
