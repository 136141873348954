import { createContext, ReactNode, useEffect } from "react";

import { useImmer } from "use-immer";
import { useMediaQueryContext } from "./MediaQueryProvider";

export type UserSettings = {
};

export type UserSettingsContextValue = UserSettings & {
  clear: () => void;
};

export const UserSettingsContext = createContext<UserSettingsContextValue>({
  clear: () => {},
});

const LS_KEY = "fintent-user-settings";

const UserSettingsProvider = ({ children }: { children: ReactNode }) => {
  const { isLowRes } = useMediaQueryContext();

  const loadUserSettings = () => {
    return JSON.parse(localStorage.getItem(LS_KEY) || "{}") as UserSettings;
  };

  const clear = () => {
    localStorage.removeItem(LS_KEY);
  };

  const [state, setState] = useImmer<{
    userSettings: UserSettings;
  }>({
    userSettings: loadUserSettings(),
  });

  // Load the state from localStorage when the component mounts
  useEffect(() => {
    const savedState = loadUserSettings();
    if (savedState) {
      setState((state) => {
        state.userSettings = savedState;
      });
    }
  }, [setState]);

  useEffect(() => {
    localStorage.setItem(LS_KEY, JSON.stringify(state.userSettings));
  }, [state.userSettings]);

  const context = {
    clear: clear,
    ...state.userSettings,
  };

  return (
    <UserSettingsContext.Provider value={context}>
      {children}
    </UserSettingsContext.Provider>
  );
};

export default UserSettingsProvider;
