import styles from "./index.module.scss";
import { ReactNode, useContext, useEffect } from "react";
import { Box } from "@mui/material";
import { PageHeaderContext } from "../providers/PageHeaderProvider";
import { useImmer } from "use-immer";
import { UserSettingsContext } from "../providers/UserSettings";
import classNames from "classnames";
import { PagesWithNavHidden } from "../constants";
import { useMediaQueryContext } from "../providers/MediaQueryProvider";

const Layout = ({ children }: { children: ReactNode }) => {
  const { isLowRes, isMediumRes, isHighRes } = useMediaQueryContext();
  const pageHeader = useContext(PageHeaderContext);
  const userSettings = useContext(UserSettingsContext);

  const navBarContainer = 5;
  const container = 5;

  const [state, setState] = useImmer<{
    offset: number;
  }>({
    offset: navBarContainer,
  });

  useEffect(() => {
    setState((state) => {
      if (pageHeader.subtitle) {
        state.offset = navBarContainer + 2 * container;
      } else {
        state.offset = navBarContainer + container;
      }
    });
  }, [pageHeader.subtitle]);

  return (
    <Box
      className={classNames(styles.layout, {
        [styles.expanded]: true,
        [styles.hideNav]: PagesWithNavHidden.includes(window.location.pathname),
        [styles.low]: isLowRes,
        [styles.medium]: isMediumRes,
        [styles.high]: isHighRes,
      })}
      sx={{
        "--offset": `${state.offset}rem`,
      }}
    >
      {children}
    </Box>
  );
};

export default Layout;
