import { createTheme, ThemeOptions } from "@mui/material";

export const themeOptions: ThemeOptions = createTheme({
  palette: {
    primary: {
      main: "#34383B",
    },
    secondary: {
      main: "#22AAE1",
    },
    success: {
      main: "#8DC640",
    },
    background: {
      default: "#FFFFFF",
    },
    info: {
      main: "#FFFFFF",
    },
  },
});
