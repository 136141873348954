import { TableHead as MTableHead } from "@mui/material";
import { TableCell as MTableCell } from "@mui/material";
import { CSSProperties, ReactNode } from "react";

import styles from "./index.module.scss";

export const ifNullThenDefault = (
  v: any,
  def: string,
  decimals: number = 2
) => {
  if (typeof v === "number") {
    v = v.toFixed(decimals);
  }
  return v === null || v === undefined ? def : v;
};

export const formatNumber = (v: any, decimal: number) => {
  if (typeof v === "number") {
    return v.toFixed(decimal);
  } else {
    return v;
  }
};

export const TableHead = ({
  children,
  sx,
}: {
  children: ReactNode;
  sx?: CSSProperties;
}) => {
  return (
    <MTableHead
      sx={{
        whiteSpace: "nowrap",
        ...sx,
      }}
      className={styles.head}
    >
      {children}
    </MTableHead>
  );
};

export const TableCell = ({
  padding,
  children,
  sx,
  className,
  style,
}: {
  padding?: "normal" | "checkbox" | "none";
  children?: ReactNode;
  sx?: CSSProperties;
  style?: CSSProperties;
  className?: string;
}) => {
  return (
    <MTableCell
      padding={padding}
      style={style}
      className={className}
      sx={{
        whiteSpace: "nowrap",
        ...sx,
      }}
    >
      {children}
    </MTableCell>
  );
};
