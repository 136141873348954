import { AlertColor } from "@mui/material/Alert";
import Toast from "../components/Toast";
import React, { createContext, ReactNode } from "react";
import { useImmer } from "use-immer";

type Message = string | ReactNode;

export type ToastContextValue = {
  setInfo: (text: Message) => void;
  setSuccess: (text: Message) => void;
  setWarning: (text: Message) => void;
  setError: (text: Message) => void;
  setMessage: (text: Message, alertColor: AlertColor) => void;
};

export const ToastContext = createContext<ToastContextValue>({
  setInfo: (text: Message) => {},
  setSuccess: (text: Message) => {},
  setWarning: (text: Message) => {},
  setError: (text: Message) => {},
  setMessage: (text: Message, alertColor: AlertColor) => {},
});

const ToastProvider = ({ children }: { children: any }) => {
  const [state, setState] = useImmer<{
    open: boolean;
    text: Message;
    alertColor: AlertColor;
    onClose: () => void;
  }>({
    open: false,
    text: "",
    alertColor: "" as AlertColor,
    onClose: () => {},
  });

  const setMessage = (text: Message, alertColor: AlertColor) => {
    setState((state) => {
      state.text = text;
      state.alertColor = alertColor;
      state.open = true;
    });
  };

  const context = {
    setInfo: (text: Message) => {
      setMessage(text, "info" as AlertColor);
    },
    setWarning: (text: Message) => {
      setMessage(text, "warning" as AlertColor);
    },
    setSuccess: (text: Message) => {
      setMessage(text, "success" as AlertColor);
    },
    setError: (text: Message) => {
      setMessage(text, "error" as AlertColor);
    },
    setMessage: setMessage,
  } as ToastContextValue;

  return (
    <ToastContext.Provider value={context}>
      {children}
      <Toast
        message={state.text}
        onClose={() => {
          setState((state) => {
            state.open = false;
          });
        }}
        open={state.open}
        severity={state.alertColor}
        style={{
          paddingBottom: "4rem",
        }}
      />
    </ToastContext.Provider>
  );
};

export default ToastProvider;
