import { cleanDomain, isURL } from "../../../utils";

export const cleanDomains = (domains: string[], checkIsURL: boolean = true) => {
  return domains
    .filter((r) => r && r.trim().length > 0)
    .map((r) => r.trim())
    .filter((r) => !checkIsURL || isURL(r))
    .map(cleanDomain);
};

export const toTitleCase = (str: string) => {
  return str
    .replaceAll("_", " ")
    .replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
};
