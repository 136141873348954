import React, { ReactNode } from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

const NavItem = ({
  primary,
  icon,
  href,
  onClick,
  active = false,
  enabled = true,
}: {
  primary: string;
  icon: ReactNode;
  href?: string;
  onClick?: () => void;
  active?: boolean;
  enabled?: boolean;
}) => {
  if (!enabled) {
    return null;
  }
  return (
    <ListItem
      disablePadding
      sx={{
        display: "block",
        color: active ? "white" : "inherit",
      }}
      component="a"
      href={href}
      onClick={onClick}
    >
      <ListItemButton
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ListItemIcon
          sx={{
            justifyContent: "center",
            color: "inherit",
          }}
        >
          {React.isValidElement(icon)
            ? React.cloneElement(icon, { sx: { fontSize: "2.5vw" } } as any)
            : icon}
        </ListItemIcon>
        <ListItemText
          primary={primary}
          sx={{
            opacity: 1,
            width: '70px',
            textAlign: 'center',
            '& .MuiListItemText-primary': {
              whiteSpace: 'normal',
              wordBreak: "break-word",
              fontSize: '0.7rem',

            },
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default NavItem;
