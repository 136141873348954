import TextField from "@mui/material/TextField";
import { CSSProperties, useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { ToastContext } from "../../providers/ToastProvider";

const SearchBar = ({
  id,
  onSearch,
  onClear,
  placeholder,
  inputProps,
  style = {},
  value,
}: {
  id?: string;
  onSearch: (keywords: string) => void;
  onClear: () => void;
  placeholder?: string;
  style?: CSSProperties;
  inputProps?: any;
  value?: string;
}) => {
  const { setError } = useContext(ToastContext);

  const [state, setState] = useImmer<{
    keywords: string;
  }>({
    keywords: "",
  });

  useEffect(() => {
    setState((state) => {
      state.keywords = value || "";
    });
  }, [value]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const trimmed = state.keywords.replace(/\W/g, "").trim();
        if (state.keywords && trimmed.length > 2) {
          onSearch(state.keywords.trim());
        } else {
          setError("Please enter more than 2 letters to run a search.");
        }
      }}
      style={style}
    >
      <TextField
        id={id}
        className="text"
        InputProps={{
          onInput: (e) => {
            const value = (e.target as HTMLInputElement).value;
            if (value === "") {
              onClear();
              setState((state) => {
                state.keywords = "";
              });
            } else {
              setState((state) => {
                state.keywords = value;
              });
            }
          },
          ...inputProps,
        }}
        value={state.keywords}
        placeholder={placeholder}
        variant="outlined"
        size="small"
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              border: "solid 0.2rem grey",
            },
          },
        }}
      />
    </form>
  );
};

export default SearchBar;
